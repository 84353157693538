import React from "react"
import {
  TitleMain,
  Button,
  Main,
  Row,
  TextButton,
  RowAndSpaceBetween,
  SubTitleSection,
  Text,
} from "../../../../components/atomics"
import {
  IMAGES_APP_FINANCES,
  URL_APP_FINANCES_ANDROID,
  URL_APP_FINANCES_IOS,
} from "../../../../constants"
import Page from "../../Page"
import Section from "../../../Section"
import { RiVipCrownFill } from "react-icons/ri"
import { useLanguage, useParams } from "../../../../hooks"
import { t } from "../../../../utils/t"
import { FEATURES, IMAGES } from "./data"

const primaryColor="#29CD83"
const Finances = () => {
  const { language } = useLanguage(`pt`)
  const {params,query} = useParams()

  const sendEvent = event => {
    if (window) {
      if (window.fbq) window.fbq("track", event)
      if (window.gtag) window.gtag("event", event)
    }
  }

  const replaceUtmSource = url => {
    if (params.utm_source && params.utm_medium) {
      if(url.includes('home')){
        return url.replace("siteFlynow", params.utm_source).replace("home", params.utm_medium)
      }else{
        return url.replace("siteFlynow", params.utm_source + '_' + params.utm_medium)
      }
    } else if (params.utm_source) {
      return url.replace("siteFlynow", params.utm_source)
    }
    return url
  }

  return (
    <Page
      title={t("flynow_finances", language)}
      logo={require(`../../../../assets/images/app/finances/logo.png`)}
      imageUrl={IMAGES_APP_FINANCES.main}
      description={t("flynow_finances_desc", language)}
      pageId="app_finances"
      primaryColor={primaryColor}
    >
      <Main>
        <div>
          <TitleMain>{t("flynow_finances_main", language)}</TitleMain>
          <Text style={{ marginTop: 10 }}>
            {t("flynow_finances_desc", language)}
          </Text>

          <Row style={{ marginTop: 30 }}>
            <Button
              style={{ backgroundColor: primaryColor }}
              onClick={() => sendEvent("download_app_finances_android")}
              href={replaceUtmSource(URL_APP_FINANCES_ANDROID)}
            >
              <img
                src={require("../../../../assets/images/playstore.png")}
                alt="playstore"
                className="icon-store"
              />
              <TextButton>{t("button_download_android", language)}</TextButton>
            </Button>
            <Button
              onClick={() => sendEvent("download_app_finances_ios")}
              style={{ marginLeft: 10, backgroundColor: primaryColor }}
              href={replaceUtmSource(URL_APP_FINANCES_IOS)}
            >
              <img
                src={require("../../../../assets/images/applestore.png")}
                alt="applestore"
                className="icon-store"
              />
              <TextButton>{t("button_download_ios", language)}</TextButton>
            </Button>
          </Row>
          <Row style={{ marginTop: 10 }}>
            <Button
              onClick={() => sendEvent("download_app_finances_web")}
              style={{ backgroundColor: primaryColor }}
              to={"plataforma-web/"+query}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={require("../../../../assets/images/web.png")}
                alt="web"
                className="icon-store"
              />
              <TextButton>{t("button_download_web", language)}</TextButton>
            </Button>
            <Button
              onClick={() => sendEvent("download_app_finances_web")}
              style={{ marginLeft: 10, backgroundColor: primaryColor }}
              to={"premium/"+query}
              target="_blank"
              rel="noopener noreferrer"
            >
              <RiVipCrownFill color="white" size={30} />
              {t("button_version_premium", language)}
            </Button>
          </Row>
        </div>
        <img
          src={IMAGES_APP_FINANCES.main}
          alt="aplicativo"
          className="img-main"
        />
      </Main>

      <Section
        title={t("why_use_our_app", language)}
        id="funcionalidades"
        color={primaryColor}
      >
      {FEATURES.map((feature, index) =>
          index % 2 === 0 ? (
            <div key={feature.title}>
              <RowAndSpaceBetween>
                <div style={{ marginTop: 40, minWidth: 330 }}>
                  <SubTitleSection>{t(feature.id, language)}</SubTitleSection>
                  <Text style={{ marginTop: 30 }}>
                    {t(feature.id + "_desc", language)}
                  </Text>
                </div>
                <img src={feature.img} alt={feature.id} className="img-func" />
              </RowAndSpaceBetween>
            </div>
          ) : (
            <div key={feature.title} style={{ marginTop: 30 }}>
              <RowAndSpaceBetween reverseWrap>
                <img src={feature.img} alt={feature.id} className="img-func" />
                <div style={{ marginTop: 40, minWidth: 330, marginLeft: 15 }}>
                  <SubTitleSection>{t(feature.id, language)}</SubTitleSection>
                  <Text style={{ marginTop: 30 }}>
                    {t(feature.id + "_desc", language)}
                  </Text>
                </div>
              </RowAndSpaceBetween>
            </div>
          )
        )}
      </Section>
      <br />
    </Page>
  )
}

export default Finances
