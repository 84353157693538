import { IMAGES_APP_FINANCES } from "../../../../constants"

export const IMAGES = IMAGES_APP_FINANCES

export const FEATURES = [
  {
    id: 'simplify_your_life',
    img: IMAGES.home
  },
  {
    id: 'incomes_expenses',
    img: IMAGES.transactions
  },
  {
    id: 'statistics_finances',
    img: IMAGES.statistics
  },
  {
    id: 'goals_finances',
    img: IMAGES.goals
  },
  {
    id: 'statistics_models_finances',
    img: IMAGES.budgets
  },
  {
    id: 'categories_finances',
    img: IMAGES.categories
  },
  {
    id: 'web_finances',
    img: IMAGES.web
  },
]
